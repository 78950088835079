'use client';

import { AbstractIntlMessages, NextIntlClientProvider } from 'next-intl';
import { PropsWithChildren } from 'react';

import { getMessageFallback, onError } from './intl-functions';

export default function IntlClientProvider({
  messages,
  locale,
  children
}: PropsWithChildren<{ messages: AbstractIntlMessages; locale: string }>) {
  return (
    <NextIntlClientProvider
      messages={messages}
      onError={onError}
      getMessageFallback={getMessageFallback}
      locale={locale}
    >
      {children}
    </NextIntlClientProvider>
  );
}
