'use client';
import { ComponentProps } from 'react';
import { CloseButton, Slide, ToastContainer } from 'react-toastify';

import { CloseIcon } from '@akva/icons';

import css from './toast-wrapper.module.css';

type CloseButtonProps = ComponentProps<typeof CloseButton>;

const AkvaCloseButton = ({ closeToast }: CloseButtonProps) => (
  <i className={css.closeButton} onClick={closeToast}>
    <CloseIcon />
  </i>
);

export default function ToastWrapper() {
  return (
    <ToastContainer
      className={css.akvaToastify}
      hideProgressBar={true}
      transition={Slide}
      theme="dark"
      closeButton={AkvaCloseButton}
    />
  );
}
